import { Component, Input, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
   selector: 'app-datepicker-clear',
   templateUrl: './datepicker-clear.component.html',
   styleUrls: ['./datepicker-clear.component.scss'],
})
export class DatepickerClearComponent {
   @Input('for') datepicker: MatDatepicker<any>;
   @Input() formCtrl?: FormControl;
   @Input() show = false;

   showClear = false;

   ngOnInit() {
      if (this.formCtrl) {
         this.formCtrl.valueChanges.subscribe((value) => {
            this.showClear = Boolean(value);
         });
      } else {
         this.showClear = this.show;
      }
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['show']) {
         this.showClear = this.show;
      }
   }

   clearInput() {
      this.datepicker.close();
      this.datepicker.select(null);
   }
}

