<mat-card class="milestones-card">
   <mat-card-title>Milestones</mat-card-title>
   <mat-card-content>
      <ng-container *ngIf="canEdit">
         <form [formGroup]="actionForm">
            <div class="next-action">
               <mat-form-field class="action-field">
                  <input
                     matInput
                     type="text"
                     formControlName="description"
                     placeholder="Milestone"
                     required
                  />
               </mat-form-field>
               <div class="date-assignee-container">
                  <app-team-member-autocomplete
                     class="action-assignee"
                     placeholder="Assigned To"
                     [idFormControl]="getControl('assigneeId')"
                     (optionSelected)="setActionAssignedToId($event)"
                     [validate]="validate$.asObservable()"
                     [required]="true"
                  ></app-team-member-autocomplete>
                  <mat-form-field class="action-date">
                     <input
                        matInput
                        formControlName="targetDate"
                        placeholder="Target Date"
                        [matDatepicker]="actionDatePicker"
                        (dateChange)="setDate($event)"
                     />
                     <app-datepicker-clear
                        class="datepicker-clear"
                        [for]="actionDatePicker"
                        [formCtrl]="getControl('targetDate')"
                     ></app-datepicker-clear>
                     <mat-datepicker-toggle
                        matSuffix
                        [for]="actionDatePicker"
                     ></mat-datepicker-toggle>
                     <mat-datepicker #actionDatePicker></mat-datepicker>
                  </mat-form-field>
                  <button
                     *ngIf="!mobile; else fullButton"
                     mat-icon-button
                     color="primary"
                     type="button"
                     (click)="addAction()"
                     [disabled]="!actionForm.valid"
                     data-test-id="add-button"
                     matTooltip="Add Milestone"
                  >
                     <mat-icon>add</mat-icon>
                  </button>
                  <ng-template #fullButton>
                     <button
                        mat-button
                        class="mobile-add-button"
                        color="primary"
                        type="button"
                        (click)="addAction()"
                        [disabled]="!actionForm.valid"
                        data-test-id="add-button"
                     >
                        Add Milestone
                     </button>
                  </ng-template>
               </div>
            </div>
         </form>
      </ng-container>

      <div class="action-list">
         <ng-container *ngFor="let action of actions">
            <div
               class="action"
               [ngClass]="{
                  completed: action.completed,
                  edit: showEditAction(action),
                  overdue: isOverdue(action)
               }"
            >
               <ng-container *ngIf="showEditAction(action); else displayAction">
                  <mat-form-field class="action-edit-text action-field">
                     <input matInput type="text" [(ngModel)]="action.description" />
                  </mat-form-field>

                  <app-team-member-autocomplete
                     class="action-edit-assignee action-field"
                     placeholder="Assigned To"
                     [value]="action.assigneeId"
                     [validate]="validate$.asObservable()"
                     (optionSelected)="setActionAssignedToId($event, action)"
                  ></app-team-member-autocomplete>
                  <mat-form-field class="action-edit-date action-field">
                     <input
                        matInput
                        placeholder="Target Date"
                        [ngModel]="action.targetDate"
                        [matDatepicker]="actionDatePicker"
                        (dateChange)="setDate($event, action)"
                     />
                     <app-datepicker-clear
                        class="datepicker-clear"
                        [for]="actionDatePicker"
                        [formCtrl]="getControl('targetDate')"
                     ></app-datepicker-clear>
                     <mat-datepicker-toggle
                        matSuffix
                        [for]="actionDatePicker"
                     ></mat-datepicker-toggle>
                     <mat-datepicker #actionDatePicker></mat-datepicker>
                  </mat-form-field>
                  <div class="action-edit-buttons">
                     <ng-container *ngIf="mobile; else desktopEditButtons">
                        <button mat-button color="primary" (click)="saveEditAction()">Save</button>
                        <button mat-button (click)="cancelEditAction(action)">Cancel</button>
                     </ng-container>
                     <ng-template #desktopEditButtons>
                        <button mat-icon-button color="primary" (click)="saveEditAction()">
                           <mat-icon>save</mat-icon>
                        </button>
                        <button mat-icon-button (click)="cancelEditAction(action)">
                           <mat-icon>cancel</mat-icon>
                        </button>
                     </ng-template>
                  </div>
               </ng-container>
               <ng-template #displayAction>
                  <div class="action-display-text">
                     <mat-checkbox
                        class="action-display-text-checkbox"
                        color="primary"
                        [disabled]="!canEdit"
                        [(ngModel)]="action.completed"
                        (click)="toggleCompleted()"
                     >
                        {{ action.description }}
                     </mat-checkbox>
                     <div class="action-display-notes">
                        <ng-container *ngIf="action.notes">
                           <mat-icon>notes</mat-icon>
                           <div class="notes-popover">
                              <div class="notes-popover-content" [innerHTML]="action.notes"></div>
                           </div>
                        </ng-container>
                     </div>
                  </div>
                  <div class="action-display-assignee">
                     {{ action.assigneeId | teamMemberName : teamMembers }}
                  </div>
                  <div class="action-display-target-date">
                     {{ action.targetDate | date : 'MM/dd/yyyy' }}
                  </div>

                  <button
                     class="action-display-menu-button"
                     mat-icon-button
                     *ngIf="canEdit"
                     [matMenuTriggerFor]="actionsMenu"
                  >
                     <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #actionsMenu="matMenu">
                     <button mat-menu-item (click)="editAction(action)">Edit</button>
                     <button mat-menu-item (click)="deleteAction(action)">Delete</button>
                  </mat-menu>
               </ng-template>
            </div>
         </ng-container>
      </div>
   </mat-card-content>
</mat-card>

<ng-template #editActionDialogTemplate>
   <div class="dialog-container">
      <div mat-dialog-title>Edit Milestone</div>
      <mat-dialog-content class="edit-action-dialog">
         <mat-form-field class="action-edit-text action-field">
            <input
               matInput
               placeholder="Milestone"
               type="text"
               [(ngModel)]="editActionCopy.description"
            />
         </mat-form-field>
         <div class="action-edit-row">
            <app-team-member-autocomplete
               class="action-edit-assignee action-field"
               placeholder="Assigned To"
               [value]="editActionCopy.assigneeId"
               [validate]="validate$.asObservable()"
               (optionSelected)="setActionAssignedToId($event, editActionCopy)"
            ></app-team-member-autocomplete>
            <mat-form-field class="action-edit-date action-field">
               <input
                  matInput
                  placeholder="Target Date"
                  [ngModel]="editActionCopy.targetDate"
                  [matDatepicker]="actionDatePicker"
                  (dateChange)="setDate($event, editActionCopy)"
               />
               <app-datepicker-clear
                  class="datepicker-clear"
                  [for]="actionDatePicker"
                  [formCtrl]="getControl('targetDate')"
               ></app-datepicker-clear>
               <mat-datepicker-toggle matSuffix [for]="actionDatePicker"></mat-datepicker-toggle>
               <mat-datepicker #actionDatePicker></mat-datepicker>
            </mat-form-field>
         </div>
         <div class="action-edit-notes">
            <div class="notes-label">Notes</div>
            <app-text-editor
               [config]="editorConfig"
               [(value)]="editActionCopy.notes"
               [canEdit]="canEdit"
            ></app-text-editor>
         </div>
      </mat-dialog-content>
      <mat-dialog-actions>
         <button mat-raised-button color="primary" (click)="saveEditAction()">Save</button>
         <button mat-stroked-button color="primary" matDialogClose>Cancel</button>
      </mat-dialog-actions>
   </div>
</ng-template>
